$( document ).on('turbolinks:load', function() {
  initBillingProfilePaymentTypeToggle();
});

var initBillingProfilePaymentTypeToggle = function(){
  const _buttons = $('[data-toggle=payment_type] .btn');
  const _active = $('[data-toggle=payment_type] [data-value=' + $("[name='billing_profile[payment_type]']:checked").first().attr('id') + ']'); 
  billingProfilePaymentTypeToggle(_active, _buttons);

  return _buttons.on('click', function(){
    billingProfilePaymentTypeToggle($(this), _buttons);
    $('#' + $(this).data('value')).prop('checked', true);
    return false;
  });
};

var billingProfilePaymentTypeToggle = function(active, buttons){
  buttons.not(active).removeClass('btn-primary').addClass('btn-outline-primary');
  active.removeClass('btn-outline-primary').addClass('btn-primary');
  $('[data-toggle=payment_type] .toggle-content').hide();
  $('[data-toggle=payment_type] .toggle-content.' + active.data('value')).show();
  return false;
};
