import { German } from "flatpickr/dist/l10n/de.js";

$( document ).on('turbolinks:load', function() {

  $('.flatpickr').flatpickr({
    locale: $('body').data('lang'),
    dateFormat: $(this).data('date-format')
  });

});
