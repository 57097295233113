import Dropzone from 'dropzone';
Dropzone.autoDiscover = false;

var preview_template_verification = "<div class=\"account-assets-asset alert alert-success mb-3\"><i class=\"far fa-check-circle\"></i><i class=\"fas fa-exclamation-triangle\"></i><i class=\"fas fa-circle-notch fa-spin\"></i>&nbsp;<span data-dz-name></span></div>";
var preview_template_avatar = "<div class=\"dz-image\"><img class='addedfile rounded-circle img-z mt-2' data-dz-thumbnail /></div>\n";

$( document ).on('turbolinks:load', function() {

  if (document.getElementById("uploadAvatar") !== null) {

    var AvatarUpload = new Dropzone("div#uploadAvatar", {
      url: "/account/avatar",
      paramName: "avatar",
      previewsContainer: "#client_avatar",
      previewTemplate: preview_template_avatar,
      uploadMultiple: false,
      acceptedFiles: 'image/*',
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      init: function() {
        this.on("addedfile", function(file, response) {
          $("#client_avatar img").not('.addedfile').remove();
        });
        this.on("complete", function(file, response) {
          $("#client_avatar .addedfile").removeClass('addedfile');
        });
        this.on("success", function(file, response) {
          $("#delete_avatar").show();
          $("#uploadAvatar").remove();
        });
      }
    });

  };

  if (document.getElementById("IdDocumentUpload") !== null) {

    var IdDocumentUpload = new Dropzone("div#IdDocumentUpload", {
      url: "/account/person_assets",
      paramName: "client_asset",
      previewsContainer: "#IdDocumentUploadPreview",
      previewTemplate: preview_template_verification,
      uploadMultiple: false,
      clickable: "#IdDocumentUpload > span",
      params: {
        client_asset_type: 'id_document'
      },
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    });

    var IncomeDocumentUpload = new Dropzone("div#IncomeDocumentUpload", {
      url: "/account/person_assets",
      paramName: "client_asset",
      previewsContainer: "#IncomeDocumentUploadPreview",
      previewTemplate: preview_template_verification,
      uploadMultiple: false,
      clickable: "#IncomeDocumentUpload > span",
      params: {
        client_asset_type: 'income_document'
      },
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    });

    var InsuranceDocumentUpload = new Dropzone("div#InsuranceDocumentUpload", {
      url: "/account/person_assets",
      paramName: "client_asset",
      previewsContainer: "#InsuranceDocumentUploadPreview",
      previewTemplate: preview_template_verification,
      uploadMultiple: false,
      clickable: "#InsuranceDocumentUpload > span",
      params: {
        client_asset_type: 'insurance_document'
      },
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    });

  };

});
