$( document ).on('turbolinks:load', function() {
  initClientTypeToggle();
});

var initClientTypeToggle = function(){
  const _buttons = $('[data-toggle=client_type] button');
  const _active = $('[data-toggle=client_type] [data-value=' + $("[name='client[role]']:checked").first().attr('id') + ']'); 
  clientTypeToggle(_active, _buttons);

  return _buttons.on('click', function(){
    clientTypeToggle($(this), _buttons);
    $('#' + $(this).data('value')).prop('checked', true);
    return false;
  });
};

var clientTypeToggle = function(active, buttons){
  buttons.not(active).removeClass('btn-primary').addClass('btn-outline-primary');
  return active.removeClass('btn-outline-primary').addClass('btn-primary');
};
